import React from 'react'
import SignUp from '../components/auth/signup'
//Need Amplify configuration here in case user navigate here directly.
import Amplify from 'aws-amplify'
import config from '../aws-exports'
Amplify.configure(config)

const PageSignUp = () => (
  <SignUp/>
)

export default PageSignUp